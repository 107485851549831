<template>
    <h3 class="py-3 text-center text-3xl font-bold dark:text-dark">Candidate Details</h3>
    <div class="d-flex align-items-center justify-content-center">
    <div class="w-full max-w-xxl px-2">
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="row">
          <div class="col-md-6 left">
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label class="block text-gray-700 text-sm font-bold mb-2 text-start" for="name">
                    Name :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.name"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label class="block text-gray-700 text-sm font-bold mb-2 text-start" for="mobile">
                    Mobile :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.mobile"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label class="block text-gray-700 text-sm font-bold mb-2 text-start" for="dob">
                    Date of Birth :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.dob"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label class="block text-gray-700 text-sm font-bold mb-2 text-start" for="gender">
                    Gender :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.gender"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 right">
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label class="block text-gray-700 text-sm font-bold mb-2 text-start" for="email">
                    Email :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.email"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label class="block text-gray-700 text-sm font-bold mb-2 text-start" for="city">
                    City :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.city"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label class="block text-gray-700 text-sm font-bold mb-2 text-start" for="state">
                    State :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.state"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label class="block text-gray-700 text-sm font-bold mb-2 text-start" for="userId">
                    User ID :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.userId"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div>
          <h6 class="py-3 text-center text-2xl font-bold dark:text-dark">Jobs Posted</h6>
          <div class="container">
              <div class="row">
                  <div v-for="(jobs, index) in list.applied" :key="index">
                      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                          <div class="row">
                              <div class="col-md-6 left">
                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="companyName"
                                    >
                                      Company Name :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.companyName"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="jobType"
                                    >
                                      job Type :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.jobType"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="salary"
                                    >
                                      Salary :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.salary"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="created"
                                    >
                                      Posted On :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.created"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                              </div>

                              <div class="col-md-6 right">

                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="jobTitle"
                                    >
                                      Job Title :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                    v-model="jobs.jobTitle"
                                    class="w-full text-gray-700 bg-transparent"
                                    type="text"
                                    />
                                  </div>
                                  </div>
                                </div>


                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="shift"
                                    >
                                      Shift :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.shift"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>


                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="status"
                                    >
                                      Status :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.status"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>


                                <div class="mb-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                    <label
                                      class="block text-gray-700 text-sm font-bold mb-2 text-start"
                                      for="education"
                                    >
                                      Education :
                                    </label>
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="jobs.education"
                                      class="w-full text-gray-700 bg-transparent"
                                      type="text"
                                    />
                                  </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                      </form>
                  </div>
              </div>
          </div>
        </div>
      <div class="flex items-center justify-center py-4">
          <button v-on:click="onDeleteCandidate()"
            class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            style="background: #0167FF;"
          >
            Delete
          </button>
        </div>
    </div>
    </div>
</template>

<script>

    import axios from "axios";
    import Swal from "sweetalert2";

    export default {
        name: "App",
        data() {
            return {
                list: {
                    name: "",
                    email: "",
                    mobile: "",
                    city: "",
                    education: "",
                    gender: "",
                    dob: "",
                },
            };
        },
        methods: {
          
    async onDeleteCandidate() {
      this.id = this.$route.params.userId;
      let params = new URLSearchParams();
      params.append("userId", this.id);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .post("https://jobsu.in/adminapis/deleteUser.php", params)
              .then(() => {

                // console.log(response);
                Swal.fire("Deleted!", "Record has been deleted.", "success");
                this.$router.push({ path: "/candidates" });
              })
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
    },
            
        },
        async mounted() {
          this.id = this.$route.params.userId;
          let params = new URLSearchParams();
          params.append('userId', this.id );
            const result = await axios.post('https://jobsu.in/adminapis/getUser.php', params)
            .then((response) => {
                    console.log(response.data);
                    this.list = response.data;
                }).catch(error=>{
                    if (error.response) {
                    console.log('Server responded with status code:', error.response.status);
                    console.log('Response data:', error.response.data);
                    } else if (error.request) {
                    console.log('No response received:', error.request);
                    } else {
                    console.log('Error creating request:', error.message);
                    }
                });
        },
    };

</script>