<template>
    <h3 class="py-3 text-center text-3xl font-bold dark:text-dark">Create Course</h3>
    <div class="d-flex align-items-center justify-content-center">
    <div class="w-full max-w-lg px-2">
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" v-on:submit.prevent="onCreateCourse()">
        <div class="mb-1">
          <label class="block text-gray-700 text-sm font-bold mb-2 text-start" for="banner">
            Banner
          </label>
          <input
            v-model="banner"
            class="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Banner"
            required
          />
        </div>
        <div class="mb-1">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="CourseName"
          >
            Course Name
          </label>
          <input
            v-model="name"
            class="shadow-sm appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Course Name"
            required
          />
        </div>
        <div class="mb-1">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="Author"
          >
            Author
          </label>
          <input
            v-model="author"
            class="shadow-sm appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Author"
            required
          />
        </div>
        <div class="mb-1">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="Price"
          >
            Price
          </label>
          <input
            v-model="price"
            class="shadow-sm appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Price"
            required
          />
        </div>
        <div class="mb-1">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="Course Duration"
          >
            Course Duration In Minutes
          </label>
          <input
            v-model="course_duration"
            class="shadow-sm appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="courseDuration"
            required
          />
        </div>
        <div class="mb-1">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="postDuration"
          >
            Post Duration
          </label>
          <input
            v-model="post_duration"
            class="shadow-sm appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Post Duration"
            required
          />

        </div>
        <div class="mb-1">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="link"
          >
            Course Link
          </label>
          <input
            v-model="course_link"
            class="shadow-sm appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Course Link"
            required
          />
        </div>
        <div class="mb-1">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="whatsApp"
          >
            WhatsApp
          </label>
          <input
            v-model="whatsapp"
            class="shadow-sm appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="WhatsApp"
            required
          />
        </div>
        <div class="mb-1">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="email"
          >
            Email
          </label>
          <input
            v-model="email"
            class="shadow-sm appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Email"
            required
          />
        </div>
        <div class="mb-1">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="phone"
          >
            Phone
          </label>
          <input
            v-model="phone"
            class="shadow-sm appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Phone Number"
            required
          />
        </div>
        <div class="flex items-center justify-center">
          <button 
            class="w-full bg-blue-950 hover:bg-blue-950 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Create Course
          </button>
        </div>
      </form>
    </div>
    </div>
</template>

<script>

    import axios from 'axios';
    import Swal from "sweetalert2";

    export default {
        name: "App",
        data() {
            return {
                banner: '',
                name: '',
                author: '',
                price: '',
                course_duration: '',
                post_duration: '',
                course_link: '',
                whatsapp: '',
                email: '',
                phone: ''
            };
        },
        methods: {
            onCreateCourse() {
                axios.post('https://jobsu.in/adminapis/createCourse.php',{
                    banner: this.banner,
                    name: this.name,
                    author: this.author,
                    price: this.price,
                    course_duration: this.course_duration,
                    post_duration: this.post_duration,
                    course_link: this.course_link,
                    whatsapp: this.whatsapp,
                    email: this.email,
                    phone: this.phone
                },
                { 
                    headers: {
                        'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                    }
                }).then((response) => {
                    console.log(response);
                    this.$router.push({path: '/courses'})
                }).then(() => {
                    Swal.fire({
                        icon: "success",
                        title: "Course Added Successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
                .catch(error=>{
                    if (error.response) {
                    console.log('Server responded with status code:', error.response.status);
                    console.log('Response data:', error.response.data);
                    } else if (error.request) {
                    console.log('No response received:', error.request);
                    } else {
                    console.log('Error creating request:', error.message);
                    }
                })
            }
        }
    };

</script>